import { createContext, useEffect, useState } from "react";
import Client from "../api-services/client";

const CompanyTypeContext = createContext(true);

export const CompanyTypeProvider = ({ children }) => {
  const [isDaughterCompany, setIsDaughterCompany] = useState();

  var client = new Client();

  useEffect(() => {
    getInfoIsDaughterCompany();
  }, []);

  const getInfoIsDaughterCompany = async () => {
    let response = await client.IsDaughterCompany();
    setIsDaughterCompany(response);
  };

  return (
    <CompanyTypeContext.Provider
      value={{ isDaughterCompany, setIsDaughterCompany }}
    >
      {children}
    </CompanyTypeContext.Provider>
  );
};

export default CompanyTypeContext;