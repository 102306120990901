class InputModel {
  constructor(
    id = 0,
    companyIdentifier = "",
    companyName = "",
    companyAddressAndCity = "",
    contractNumber = "",
    subjectMatterOfTheContract = "",
    responsiblePersonFirstName = "",
    responsiblePersonLastName = "",
    responsiblePersonEmail = "",
    responsiblePersonPhoneNumber = "",
    replacementResponsiblePersonFirstName = "",
    replacementResponsiblePersonLastName = "",
    replacementResponsiblePersonEmail = "",
    replacementResponsiblePersonPhoneNumber = "",
    bzrResponsiblePersonFirstName = "",
    bzrResponsiblePersonLastName = "",
    bzrResponsiblePersonPhoneNumber = "",
    bzrResponsiblePersonEmail = "",
    bzrWithContract = true,
    createdByBzrOfficer = false,
    status = 0,
    inputWorkflowPeople = [],
    inputWorkflowEquipments = [],
    fkSapcontract = null,
    fkDaughterCompany = null,
    fromDaughterCompany = false,
    inputForNextYear = false
  ) {
    this.id = id;
    this.companyIdentifier = companyIdentifier;
    this.companyName = companyName;
    this.companyAddressAndCity = companyAddressAndCity;
    this.contractNumber = contractNumber;
    this.subjectMatterOfTheContract = subjectMatterOfTheContract;
    this.responsiblePersonFirstName = responsiblePersonFirstName;
    this.responsiblePersonLastName = responsiblePersonLastName;
    this.responsiblePersonEmail = responsiblePersonEmail;
    this.responsiblePersonPhoneNumber = responsiblePersonPhoneNumber;
    this.replacementResponsiblePersonFirstName = replacementResponsiblePersonFirstName;
    this.replacementResponsiblePersonLastName = replacementResponsiblePersonLastName;
    this.replacementResponsiblePersonEmail = replacementResponsiblePersonEmail;
    this.replacementResponsiblePersonPhoneNumber = replacementResponsiblePersonPhoneNumber;
    this.bzrResponsiblePersonFirstName = bzrResponsiblePersonFirstName;
    this.bzrResponsiblePersonLastName = bzrResponsiblePersonLastName;
    this.bzrResponsiblePersonPhoneNumber = bzrResponsiblePersonPhoneNumber;
    this.bzrResponsiblePersonEmail = bzrResponsiblePersonEmail;
    this.bzrWithContract = bzrWithContract;
    this.createdByBzrOfficer = createdByBzrOfficer;
    this.status = status;
    this.inputWorkflowPeople = inputWorkflowPeople;
    this.inputWorkflowEquipments = inputWorkflowEquipments;
    this.fkSapcontract = fkSapcontract;
    this.fkDaughterCompany = fkDaughterCompany;
    this.fromDaughterCompany = fromDaughterCompany;
    this.inputForNextYear = inputForNextYear;
  }
}

class InputSearchModel {
  constructor(ledgerNumber = "", companyName = "", contractNumber = "", statuses = null) {
    this.ledgerNumber = ledgerNumber;
    this.companyName = companyName;
    this.contractNumber = contractNumber;
    this.statuses = statuses;
  }
}

class InputPersonModel {
  constructor(
    id = null,
    firstName = "",
    lastName = "",
    employeePosition = "",
    identityCardNumber = "",
    identityCardPlace = "",
    identityPassportNumber = "",
    identityPassportPlace = "",
    foreigner = false
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.employeePosition = employeePosition;
    this.identityCardNumber = identityCardNumber;
    this.identityCardPlace = identityCardPlace;
    this.identityPassportNumber = identityPassportNumber;
    this.identityPassportPlace = identityPassportPlace;
    this.foreigner = foreigner;
  }
}

class InputEquipmentModel {
  constructor(name = "", model = "", type = "", identificationNumber = "") {
    this.name = name;
    this.model = model;
    this.type = type;
    this.identificationNumber = identificationNumber;
  }
}

export { InputModel, InputSearchModel, InputPersonModel, InputEquipmentModel };
